import Lottie from "react-lottie"

export default class LottieWithAnimationControl extends Lottie {
  componentDidUpdate(prevProps, prevState, prevContext) {
    if (super.componentDidUpdate) {
      super.componentDidUpdate(prevProps, prevState, prevContext)
    }

    if (this.props.percentage === 0) {
      this.anim.goToAndStop(0, true)
    }

    if (
      this.props.percentage !== prevProps.percentage &&
      this.anim.totalFrames
    ) {
      const frame = Math.round(this.anim.totalFrames * this.props.percentage)

      if (frame < this.anim.totalFrames) {
        this.anim.goToAndStop(frame, true)
      } else {
        this.anim.goToAndStop(this.anim.totalFrames - 1, true)
      }
    }
  }
}
