import React from "react"
import PropTypes from "prop-types"
import showdown from "showdown"

const converter = new showdown.Converter()

const MarkdownContent = ({ content, className }) => {
  if (content) {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: converter.makeHtml(content.replace(/\\/g, "  ")),
        }}
      />
    )
  } else {
    return null
  }
}

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

export default MarkdownContent
