import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Navbar from "../components/Navbar/Navbar"
import MarkdownContent from "../components/MarkdownContent"
import Lottie from "../components/LottieWithAnimation/LottieWithAnimation"
import * as animationData from "../animation/artifier.json"
import "../css/imprint.css"

const defaultOptions = {
  loop: false,
  autoplay: false,
  animationData: animationData.default,
  speed: 0.1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

export const ImprintTemplate = ({ imprint, lndp }) => {
  return (
    <div className="container">
      <Navbar isVisible={true} imprint={true} />
      <div className="imprint-logo-container">
        <div className="imprint-logo">
          <Lottie options={defaultOptions} percentage={1} cl />
        </div>
      </div>
      <section id="imprint">
        <MarkdownContent className="imprint-text" content={imprint} />
      </section>
      <section id="legal-notice-data-privacy">
        <MarkdownContent className="imprint-text" content={lndp} />
      </section>
    </div>
  )
}

const ImprintPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO title="Imprint" />
      <ImprintTemplate imprint={frontmatter.imprint} lndp={frontmatter.lndp} />
    </Layout>
  )
}

export default ImprintPage

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        imprint
        lndp
      }
    }
  }
`
