import React, { useState } from "react"
import { Link } from "react-scroll"
import "./Navbar.css"

const Navbar = ({ isVisible, imprint, isBottom }) => {
  const [mobileNavBarIsActive, setMobileNavBarIsActive] = useState(false)

  const handleClick = () => {
    setMobileNavBarIsActive(!mobileNavBarIsActive)
  }
  const hideMobileNavbar = () => {
    setMobileNavBarIsActive(false)
  }

  return (
    <nav className={`navbar ${isVisible ? "is-active" : ""}`}>
      <ul className={`navbar__list ${mobileNavBarIsActive ? "is-active" : ""}`}>
        <li className="navbar__list__item">
          {imprint ? (
            <a href="/#about">About</a>
          ) : (
            <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              onClick={hideMobileNavbar}
            >
              About
            </Link>
          )}
        </li>
        <li className={`navbar__list__item ${isBottom ? "not-active" : ""}`}>
          {imprint ? (
            <a href="/#artists">Artists</a>
          ) : (
            <Link
              activeClass="active"
              to="artists"
              spy={true}
              smooth={true}
              duration={500}
              onClick={hideMobileNavbar}
            >
              Artists
            </Link>
          )}
        </li>
        <li className={`navbar__list__item ${isBottom ? "is-active" : ""}`}>
          {imprint ? (
            <a href="/#contact">Contact</a>
          ) : (
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              onClick={hideMobileNavbar}
            >
              Contact
            </Link>
          )}
        </li>
      </ul>
      <button
        className={`hamburger hamburger--collapse ${
          mobileNavBarIsActive ? "is-active" : ""
        }`}
        type="button"
        onClick={handleClick}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </nav>
  )
}

export default Navbar
